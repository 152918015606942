import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

const defaultBlacklist = [
'aboard','about','above','across','after','against',
'along','amid','among','anti','around','as','at','before',
'behind','below','beneath','beside','besides','between',
'beyond','but','by','concerning','considering','despite',
'down','during','except','excepting','excluding','following',
'for','from','in','inside','into','like','minus',
'near','of','off','on','onto','opposite','outside','over',
'past','per','plus','regarding','round','save',
'since','than','through','to','toward','towards',
'under','underneath','unlike','until','up','upon',
'versus','via','with','within','without',

'an','the','and',
"I", "you", "he", "she", "it", "we", "they",
 "you", "him", "her", "it", "us", "them",
"my", "your", "his", "her", "it's", "our", "their",
"mine", "yours", "his", "hers", "its", "ours", "theirs",
"myself", "yourself", "himself", "herself", "itself", "ourselves", "themselves",
"can", "could", "may", "might", "shall", "should",
 "would", "must", 
"a","b","c","d","e","f","g","h","i","j","k","l","m",
"n","o","p","q","r","s","t","u","v","w","x","y","z",
];/*"me",*/ /*"will",*/

const emptyBlackList = [];

const BlacklistManager = ({ onUpdate }) => {
  const [showModal, setShowModal] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [blacklist, setBlacklist] = useState([]);

  useEffect(() => {
    // Load blacklist from localStorage or set to default
    const storedBlacklist = localStorage.getItem('userBlacklist');
    const blacklist = storedBlacklist ? JSON.parse(storedBlacklist) : defaultBlacklist;
    setInputValue(blacklist.join(', '));
    onUpdate(blacklist);
  }, []);

  const handleSave = () => {
    const newList = inputValue.split(',').map(word => word.trim().toLowerCase()).filter(word => word);
    onUpdate(newList);
    localStorage.setItem('userBlacklist', JSON.stringify(newList));
    setShowModal(false);
  };

  const handleResetDefault = () => {
    setInputValue(defaultBlacklist.join(', '));
    onUpdate(defaultBlacklist);
    setBlacklist(defaultBlacklist);
  };
  
  return (
    <>
      <Button 
        className={`
          btn float-end mx-2 
          btn-warning
          rounded-pill border-0
        `}
        onClick={() => setShowModal(true)}
        >
          Manage Blacklist
      </Button>

      <Modal show={showModal} onHide={() => setShowModal(false)} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Edit Blacklist</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Blacklisted Words (use commas to seperate)</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                style={{minHeight:'400px'}}
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                placeholder="You can enter multiple words separated by commas ie. this,that"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="danger" onClick={handleResetDefault}>
            Reset to Default
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BlacklistManager;