import React, { useEffect, useState } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';

const TwitchChatEmoteSorter = ({ messages, maxSize, allowSpam }) => {
    const [commonWords, setCommonWords] = useState([]);
    const [showEmoteText, setShowEmoteText] = useState(false);

    const blacklist = [
        "is", "the", "that", "th", "tha", "this"
    ];

    const countWordsWithoutSpam = (messages) => {
        const wordCounts = {};
        messages.forEach(({ content, emoteData }) => {
            const uniqueWords = new Set();
            emoteData.forEach(({ url, emoteId, start, end, type, emoteText }) => {
                const emoteTextKey = emoteText.toLowerCase();  // Normalize the case for consistent comparison
                if (!blacklist.includes(emoteTextKey)) {  // Check against blacklist
                    uniqueWords.add(emoteTextKey);  // Add to set to ensure it's counted only once per message
                    if (!wordCounts[emoteTextKey]) {
                        wordCounts[emoteTextKey] = {
                            count: 0,
                            url: url  // Using the provided URL for emotes
                        };
                    }
                }
            });

            // Now iterate over the set to update counts
            uniqueWords.forEach(word => {
                wordCounts[word].count++;
            });
        });

        return wordCounts;  
    }

    const countWords = (messages) => {
        if (!allowSpam) {
            return countWordsWithoutSpam(messages);
        }

        const wordCounts = {};

        messages.forEach(({ emoteData }) => {
            emoteData.forEach(({ url, emoteText }) => {
                const normalizedText = emoteText.toLowerCase();
                //console.log(normalizedText);
                const words = normalizedText.split(/\s+/).filter(word => word.length > 0 && !blacklist.includes(word));

                words.forEach(word => {
                    // Initialize word count and url if not already done
                    if (!wordCounts[word]) {
                        wordCounts[word] = {
                            count: 1,
                            url: url  // Store the URL for the emote
                        };
                    } else {
                        wordCounts[word].count++;
                    }
                });
            });
        });

        return wordCounts;
    };

    useEffect(() => {
        const wordCounts = countWords(messages);
        setCommonWords(Object.entries(wordCounts)
            .sort((a, b) => b[1].count - a[1].count)
            .slice(0, maxSize || 25)
            .map(([word, data]) => ({
                word,
                count: data.count,
                url: data.url  // Only exists for emotes
            }))
        );
    }, [messages, allowSpam]);

    return (
        <div>
            <h5 className="mt-2">
              Emotes
              <Form.Switch
                type="switch"
                className="float-end"
                checked={showEmoteText}
                onChange={() => setShowEmoteText(prevState => !prevState)}
              />
            </h5>
            <ListGroup variant="flush" as="ol" numbered>
                {commonWords.slice(0, 15).map((item) => (
                    <ListGroup.Item 
                      className="text-break"
                      key={item.word} 
                      style={{ background: '#1a1d20', color: '#dee2e6' }}
                    >
                      {item.url ? (
                        <>
                          <img 
                            src={item.url}
                            style={{ marginRight: 5, height: '28px' }} 
                          />
                            {showEmoteText ? item.word : ""}
                        </>
                      ) : item.word}
                      <Badge bg="primary" pill className="float-end">
                        {item.count}
                      </Badge>
                    </ListGroup.Item>
                ))}
            </ListGroup>
        </div>
    );
};

export default TwitchChatEmoteSorter;
