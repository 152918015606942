import axios from 'axios';

const TWITCH_CLIENT_ID = process.env.REACT_APP_TWITCH_CLIENT_ID;
const TWITCH_REDIRECT_URI = process.env.REACT_APP_TWITCH_REDIRECT_URI;

const defaultScopes = ['chat:read'];

const getLoginUrl = (state = '') => {
  return encodeURI(
    `https://id.twitch.tv/oauth2/authorize?client_id=${process.env.REACT_APP_TWITCH_CLIENT_ID}` +
    `&redirect_uri=${process.env.REACT_APP_TWITCH_REDIRECT_URI}` +
    `&response_type=token` +
    `&scope=${defaultScopes.join(' ')}` +  // Ensure 'user:read:email' is not included unless needed
    `&claims={"userinfo":{"picture":null, "preferred_username":null}}` +
    `&state=${state}`
  );
};

const redirectToLogin = (state) => {
  window.location.assign(getLoginUrl(state));
};

const validateToken = async (token) => {
  const { data, status } = await axios.get(`https://id.twitch.tv/oauth2/validate`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return { data, status };
};

const getUserInfo = async (token) => {
  const { data, status } = await axios.get(`https://id.twitch.tv/oauth2/userinfo`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return { data, status };
};

const revokeToken = async (token) => {
  await axios.post(`https://id.twitch.tv/oauth2/revoke`, `client_id=${TWITCH_CLIENT_ID}&token=${token}`, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
};

const TwitchAuthApi = {
  getLoginUrl,
  redirectToLogin,
  revokeToken,
  validateToken,
  getUserInfo,
};

export default TwitchAuthApi;
