import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import LoginButton from './LoginButton';
import Container from 'react-bootstrap/Container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitch, faDiscord } from '@fortawesome/free-brands-svg-icons';
import {
  Row,
  Col
} from 'react-bootstrap';

const Home = () => {
    const { token } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        console.log('Token:', token); // Debugging to see the token state
        if (token && token !== "") {
            navigate('/poll'); // Navigate to the poll page if a valid token is present
        }
    }, [token, navigate]);

    return (
        <Container className="text-white pt-5">
            <h1 className="text-center">Chat Press One!</h1>
            
            <p className="mb-5 text-center">
              An app for use with Twitch, created by 
              <b> 
                 <FontAwesomeIcon icon={faDiscord} className="mx-1" />
                ChunkySpaceman
              </b>.
            </p>
            <Row>
              <Col xs={12} className="d-flex justify-content-center">
                <LoginButton />
              </Col>
              <Col xs={12} className="d-flex justify-content-center">
                <p className="text-center mt-2">
                 This app does not save any Twitch data; you must relog if you refresh.
                </p>
              </Col>
            </Row>
            <h2 className="mt-5">
              What is it?
            </h2>
            <p>
            This app records a rolling record of chat messages and displays the top 15 similar messages, individual words, emotes, and numbers in separate lists. You can use this for quick real-time polling without creating a Twitch poll—hence the name, "Chat Press One."
            </p>
            <h2 className="mt-5">
              What else can it do?
            </h2>
            <p>There are currently four inputs on the app:</p>
            <ul>
            <li>
            <b>Clear chat history button:</b> self-explanatory.
            </li>
            <li>
            <b>Reading Messages button:</b> pauses incoming new messages if you want to use the app as a poll and view the results.
            </li>
            <li>
            <b>Keyword filter:</b> shows only messages that contain a specific keyword (such as 'vote 1' or 'poll blue'). Leaving the keyword blank shows all messages.
            </li>
            <li>
            <b>Max Messages:</b> limits the number of chat messages to filter through. More is better for a more active chat, but it may slow down your web browser.
            </li>
            </ul>
            <p>
            There is a list of small words, such as articles, prepositions, and individual letters, that have been automatically filtered out. They can be enabled if you wish.
            </p>
            <h2 className="mt-5">Who am I?</h2>
            <p>
            I am ChunkySpaceman, a full-stack engineer, IoT dev, mobile app dev, and full stack web developer who enjoys building stuff. Reach out to me on discord if you have an idea or project. This project was designed with and for 
              <a href="https://twitch.tv/cyr" target="_blank" rel="noopener noreferrer"> 
                <FontAwesomeIcon icon={faTwitch} className="mx-1" /> Cyr
              </a>.
            </p>
        </Container>
    );
};

export default Home;
