// LoginButton.js
import React from 'react';
import TwitchAuthApi from './TwitchAuthApi'; 
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitch, faDiscord } from '@fortawesome/free-brands-svg-icons'

const LoginButton = () => {
    const handleLogin = () => {
        TwitchAuthApi.redirectToLogin();
    };

    return (
          <Button 
            variant="secondary" 
            size="md" 
            style={{ backgroundColor: '#6441a4' }}
            onClick={handleLogin}
            className="rounded-pill border-0"
          >
            <FontAwesomeIcon icon={faTwitch} className="mx-1" />
            Login with Twitch
          </Button>
    );
};

export default LoginButton;
