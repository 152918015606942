import React, { 
  useContext,
  useState,
  useCallback
  } from 'react';
import { AuthContext } from './AuthContext';
import TwitchChatReader from './TwitchChatReader';
import TwitchChatSorter from './TwitchChatSorter';
import TwitchChatMessageSorter from './TwitchChatMessageSorter';
import TwitchChatNumberSorter from './TwitchChatNumberSorter';
import TwitchChatEmoteSorter from './TwitchChatEmoteSorter';
import WordCloudModal from './WordCloudModal';
import BlacklistManager from './BlacklistManager';

//import TwitchChat from './TwitchChat';
import { 
  ListGroup,
  InputGroup,
  Form,
  Row,
  Col,
  Container,
  FormControl,
  Button
} from 'react-bootstrap';

import { Navigate } from 'react-router-dom';

const Poll = () => {
    const { user } = useContext(AuthContext);
    const [isReading, setIsReading] = useState(true);
    const [allowSpam, setAllowSpam] = useState(false);
    const [maxSize, setMaxSize] = useState(200); 
    const [chatMessages, setChatMessages] = useState([]);
    const [newChatMessages, setNewChatMessages] = useState([]);
    const [keyword, setKeyword] = useState('');
    const [wordList, setWordList] = useState([]);
    const [allowBlacklist, setAllowBlacklist] = useState(false);
    const [blacklist, setblacklist] = useState(false);
    
    const handleUpdateBlacklist = (newBlacklist) => {
      setblacklist(newBlacklist);
    };

  
  
    const handleNewChatMessages = (newMessages) => {
        setNewChatMessages(newMessages);
    };
    
    const handleNewMessages = (newMessages) => {
        setChatMessages(prev => {
            // Combine old and new messages
            const updatedMessages = [...prev, ...newMessages];
            // Check if the combined array exceeds the maximum size
            if (updatedMessages.length > maxSize) {
                // Remove the oldest messages to maintain the maximum size
                return updatedMessages.slice(updatedMessages.length - maxSize);
            }
            return updatedMessages;
        });
        setNewChatMessages(newMessages);
    };
    
    
    // Check if the user is not logged in and redirect
    if (!user) {
      return <Navigate to="/" replace />;
    }
    //console.log(user);
    //user.sub is the id


    const handleClearLocalStorage = () => {
      localStorage.clear();
      window.location.reload();  // Optionally reload the page to reflect changes
    };
    
    const handleKeywordChange = (event) => {
      setKeyword(event.target.value);
    };
    
    const handleWordsUpdate = (words) => {
      setWordList(words);
    };


    return (
        <Container fluid className="text-white">
          <Row className="py-3">
            <Col sm={4}>
              <h4>
              Welcome, {user.preferred_username}
              </h4>
            </Col>
            <Col sm={8}>
              
              <BlacklistManager onUpdate={handleUpdateBlacklist} />

              
              <button
                  onClick={() => setAllowBlacklist(!allowBlacklist)}
                  className={`btn float-end mx-2 
                    ${allowBlacklist ? 'btn-danger' : 'btn-secondary'} 
                    rounded-pill border-0`}
              >
                  {allowBlacklist ? 'Allowing Blacklist' : 'Blocking Blacklist'}
              </button>
              <button
                  onClick={() => setAllowSpam(!allowSpam)}
                  className={`btn float-end mx-2 
                    ${allowSpam ? 'btn-danger' : 'btn-secondary'} 
                    rounded-pill border-0`}
              >
                  {allowSpam ? 'Counting Spam' : 'Not Counting Spam'}
              </button>
            </Col>
          </Row>
          <Row>
            <Col sm={3}>

              <InputGroup className="mb-3">
                  <FormControl
                      placeholder="Keyword Filter"
                      aria-label="Keyword Filter"
                      aria-describedby="basic-Keyword"
                      type="text"
                      value={keyword}
                      onChange={handleKeywordChange} // Passing the function directly
                  />
                  <InputGroup.Text id="basic-Keyword">Keyword</InputGroup.Text>
              </InputGroup>
            </Col>
            <Col sm={3}>
              <InputGroup className="mb-3">
                <FormControl
                  placeholder="Message Count"
                  aria-label="Message Count"
                  aria-describedby="basic-maxSize"
                  type="number"
                  min="100"  // Minimum value set to 100
                  value={maxSize}
                  onChange={e => setMaxSize(Number(e.target.value))}
                />
                <InputGroup.Text id="basic-maxSize">Max Messages</InputGroup.Text>
              </InputGroup>
            </Col>
            <Col sm={6}>
            
              <button
                  onClick={() => setIsReading(!isReading)}
                  className={`btn float-end mx-2 ${isReading ? 'btn-dark' : 'btn-danger'} rounded-pill border-0`}
                  style={{ 
                    width:'160px',
                    backgroundColor: isReading ? '#6441a5' : '' 
                  }} // Twitch purple
              >
                  {isReading ? 'Pause Chat' : 'Paused'}
              </button>
              <button 
                className={`btn float-end btn-secondary mx-2 rounded-pill border-0`}
                onClick={() => setChatMessages([])}
              >
                Clear Chat
              </button>
              <WordCloudModal words={wordList} />

            </Col>
            <Col sm={5}>
              <TwitchChatMessageSorter 
                messages={chatMessages} 
                keyword={keyword} 
                maxSize={maxSize} 
              />
            </Col>

            <Col sm={3}>
              <TwitchChatSorter
                messages={chatMessages} 
                keyword={keyword} 
                maxSize={maxSize} 
                handleWordsUpdate={handleWordsUpdate}
                allowSpam={allowSpam}
                newChatMessages={newChatMessages}
                allowBlacklist={allowBlacklist}
                blacklist={blacklist}
              />
            </Col>
            <Col sm={2}>
              <TwitchChatEmoteSorter 
                messages={chatMessages} 
                maxSize={maxSize} 
                allowSpam={allowSpam}
              />
            </Col>
            <Col sm={2}>
              <TwitchChatNumberSorter 
                messages={chatMessages} 
                maxSize={maxSize} 
                allowSpam={allowSpam}
              />
            </Col>

          </Row>

          <TwitchChatReader 
            isReading={isReading} 
            channel={user.preferred_username == 'ChunkySpaceman' ? 'CYR' : user.preferred_username}
            id={user.preferred_username == 'ChunkySpaceman' ? '37522866' : user.sub}
            //channel={user.preferred_username}
            //id={user.sub}
            
            onNewMessages={handleNewMessages} 
          />
        </Container>
    );
};

export default Poll;
