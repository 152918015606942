import axios from 'axios';

/**
 * Fetches and processes emotes from the provided URL.
 * If fetching global emotes, expects response.data to contain emote data directly.
 * If fetching channel or shared emotes, expects response.data.channelEmotes or response.data.sharedEmotes.
 * @param {string} url - The URL to fetch emotes from.
 * @returns {Promise<Array>} A promise that resolves to an array of emote objects.
 */
const fetchEmotes = async (url) => {
    try {
        const response = await axios.get(url);
        let emotes = [];

        // Check and extract global emotes if the URL is for global emotes
        if (url.includes('emotes/global')) {
            emotes = response.data; // Global emotes are directly in data
        } else {
            // Combine channel-specific and shared emotes into one array
            emotes = [
                ...(response.data.channelEmotes || []),
                ...(response.data.sharedEmotes || [])
            ];
        }

        // Map emotes to the desired structure
        return emotes.map(emote => ({
            name: emote.code,
            id: emote.id,
            url: `https://cdn.betterttv.net/emote/${emote.id}/1x.webp`
        }));
    } catch (error) {
        console.error(`Error fetching emotes from ${url}:`, error);
        return [];  // Return an empty array in case of an error
    }
};

const fetchBTTVEmotes = async (channelId) => {
    const bttvGlobalUrl = 'https://api.betterttv.net/3/cached/emotes/global';
    const bttvChannelUrl = `https://api.betterttv.net/3/cached/users/twitch/${channelId}`;

    try {
        // Fetch global and channel-specific emotes concurrently
        const globalEmotesPromise = fetchEmotes(bttvGlobalUrl);
        const channelEmotesPromise = fetchEmotes(bttvChannelUrl);

        // Wait for both promises to resolve
        const [globalEmotes, channelEmotes] = await Promise.all([globalEmotesPromise, channelEmotesPromise]);
        return [...globalEmotes, ...channelEmotes];
    } catch (error) {
        console.error('Error combining BTTV emote results:', error);
        return [];  // Return an empty array if final combination fails
    }
};

export default fetchBTTVEmotes;