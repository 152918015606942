import React, { useEffect, useRef, useState } from 'react';
import tmi from 'tmi.js';
import fetch7TVEmotes from '../utils/fetch7TVEmotes';
import fetchFFZEmotes from '../utils/fetchFFZEmotes';
import fetchBTTVEmotes from '../utils/fetchBTTVEmotes';

const TwitchChatReader = ({ isReading, channel, id, onNewMessages }) => {
  const client = useRef(null);
  const messageBuffer = useRef([]);
  const intervalId = useRef(null);
  const [emotes, setEmotes] = useState([]);  // State to store all emotes
  const [emotesLoaded, setEmotesLoaded] = useState(false);  // State to indicate if emotes have been loaded

  const loadEmotes = async () => {
    if (channel) {
      const emotes7tv = await fetch7TVEmotes(id);
      const emotesffz = await fetchFFZEmotes(id);
      const emotesbttv = await fetchBTTVEmotes(id);

      // Combine all emotes into one array
      const combinedEmotes = [...emotes7tv, ...emotesffz, ...emotesbttv];

      // Deduplicate emotes
      const emoteMap = new Map();
      combinedEmotes.forEach(emote => {
        // Assuming each emote has a 'name' property which is unique
        if (!emoteMap.has(emote.name)) {
          emoteMap.set(emote.name, emote);
        }
      });

      // Set the unique emotes into state
      setEmotes(Array.from(emoteMap.values()));
      setEmotesLoaded(true);
    }
  };

  useEffect(() => {
    loadEmotes();
  }, [channel, id]);

  const sanitizeMessage = (message) => {
    return message.replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/'/g, '').replace(/,/g, '');
  };

  const escapeRegExp = (text) => {
    return text.replace(/[-[\]/{}()*+?.\\^$|#\s]/g, '\\$&');
  };

const extractEmotes = (message, twitchEmotes, additionalEmotes) => {
    const sanitizedMessage = message;//sanitizeMessage(message);
    const words = message.trim().split(/\s+/);//sanitizedMessage.split(' '); // Split message by spaces
    const emoteData = [];
    let currentIndex = 0;

    words.forEach(word => {
        // Strip potential leading or trailing punctuation if it could be attached to emotes
        //const cleanWord = word.replace(/^[\.,-\/#!$%\^&\*;:{}=\-_`~()]+|[\.,-\/#!$%\^&\*;:{}=\-_`~()]+$/g, '');
        const emote = additionalEmotes.find(e => e.name === word);//cleanWord);
        if (emote) {
            emoteData.push({
                emoteId: emote.id,
                start: currentIndex,
                end: currentIndex + word.length -1, //cleanWord.length - 1,
                emoteText: word,//cleanWord,
                url: emote.url
            });
        }
        currentIndex += word.length + 1; // Increment index by word length plus one space
    });

    // Handle Twitch emotes provided by the tmi.js tags
    if (twitchEmotes) {
        Object.entries(twitchEmotes).forEach(([emoteId, positions]) => {
            positions.forEach(position => {
                const [start, end] = position.split('-').map(Number);
                const emoteText = message.substring(start, end + 1);
                emoteData.push({
                    emoteId:emoteId,
                    start: start,
                    end: end,
                    emoteText: emoteText,
                    url: `https://static-cdn.jtvnw.net/emoticons/v2/${emoteId}/default/light/1.0`
                });
            });
        });
    }

    return {
        content: sanitizedMessage,
        emoteData,
        originalContent: message
    };
};

  useEffect(() => {
    if (!emotesLoaded || !isReading) return;

    const setupClient = () => {
      if (client.current && client.current.readyState() === 'OPEN') return;

      client.current = new tmi.Client({
        options: { 
          debug: false,
          skipUpdatingEmotesets: true, 
          skipMembership: true 
        },
        connection: { secure: true, reconnect: true },
        channels: [channel]
      });

      client.current.connect().catch(console.error);

      client.current.on('message', (channel, tags, message, self) => {
        if (!self) {
          const messageWithEmotes = extractEmotes(message, tags.emotes, emotes);
          messageBuffer.current.push(messageWithEmotes);
        }
      });
    };

    setupClient();

    return () => {
      if (client.current) {
        client.current.disconnect();
        client.current.removeAllListeners();
        client.current = null;
      }
    };
  }, [channel, isReading, emotesLoaded, emotes]);

  useEffect(() => {
    const handleMessagePosting = () => {
      if (messageBuffer.current.length > 0) {
        onNewMessages([...messageBuffer.current]);
        messageBuffer.current = [];
      }
    };

    if (isReading && channel) {
      intervalId.current = intervalId.current || setInterval(handleMessagePosting, 1250);
    } else {
      if (intervalId.current) {
        clearInterval(intervalId.current);
        intervalId.current = null;
      }
    }

    return () => {
      if (intervalId.current) {
        clearInterval(intervalId.current);
        intervalId.current = null;
      }
    };
  }, [isReading, channel, onNewMessages]);

  return null; // This component does not render anything visually
};

export default TwitchChatReader;
