import React, { useEffect, useState } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import Badge from 'react-bootstrap/Badge';

const TwitchChatNumberSorter = ({ messages, maxSize, allowSpam  }) => {
    const [commonNumbers, setCommonNumbers] = useState([]);

    // Function to count numbers across all messages
    const countNumbers = (messages) => {
        const numberCounts = {};
        messages.forEach(message => {
            if (allowSpam) {
                // If allowSpam is true, count all instances of each number
                const allNumbers = message.content.match(/\b\d+\b/g) || [];
                allNumbers.forEach(number => {
                    numberCounts[number] = (numberCounts[number] || 0) + 1;
                });
            } else {
                // Use a Set to store numbers found in the message to ensure uniqueness
                const uniqueNumbers = new Set(message.content.match(/\b\d+\b/g) || []);
                // Increment the count for each unique number found in the message
                uniqueNumbers.forEach(number => {
                    numberCounts[number] = (numberCounts[number] || 0) + 1;
                });
            }
        });
        return numberCounts;
    };

    const getTopTenNumbers = (numberCounts) => {
        return Object.entries(numberCounts)
            .sort((a, b) => b[1] - a[1]) // Sort by frequency
            .slice(0, 15) // Get the top fifteen, based on your adjustment
            .map(([number, count]) => ({ number, count })); // Return as objects
    };

    // Effect to handle number counting and sorting
    useEffect(() => {
        const numberCounts = countNumbers(messages);
        setCommonNumbers(getTopTenNumbers(numberCounts));
    }, [messages, allowSpam]);

    return (
        <div>
            <h5 className="mt-2">
              Numbers
            </h5>
            <ListGroup variant="flush" as="ol" numbered>
                {commonNumbers.map((item, index) => (
                  <ListGroup.Item 
                    key={index} 
                    className="text-break"
                    style={{ 
                      background:'#1a1d20',
                      color:'#dee2e6',
                      overflowX:'hidden',
                      whiteSpace: 'nowrap'
                    }}
                  > 
                    {item.number} 
                    <Badge bg="primary" pill className="float-end">
                      {item.count}
                    </Badge>
                  </ListGroup.Item>
                ))}
            </ListGroup>
        </div>
    );
};

export default TwitchChatNumberSorter;