import React, { useState, useEffect, useRef } from 'react';
import { Text } from '@visx/text';
import { scaleLog, scaleLinear } from '@visx/scale';
import { interpolateRgb } from 'd3-interpolate';
import VisxWordcloud from '@visx/wordcloud/lib/Wordcloud';

const WordCloud = ({ words }) => {
  const [dimensions, setDimensions] = useState({ width: 800, height: 600 });
  const [spiralType, setSpiralType] = useState('rectangular');
  const [withRotation, setWithRotation] = useState(false);
  const containerRef = useRef(null);
  useEffect(() => {
    const updateSize = () => {
      if (containerRef.current) {
        const width = containerRef.current.offsetWidth;
        setDimensions({ width, height: width / 2 });
      }
    };
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  
  useEffect(() => {
    //console.log(words.length);
  }, [words]);

/*
  const fontScale = scaleLog({
    domain: [1, Math.max(...words.map(w => w.count), 1)],
    range: [5, 50]
  });
  
  const fontSizeSetter = (datum) => fontScale(datum.count);
  
  const baseFontSize = dimensions.height * 0.035; // E.g., 2% of the container's height

const fontSizeSetter = (datum) => {
    const calculatedSize = baseFontSize + (datum.count / 2 * datum.count / 2 * baseFontSize * 0.1); // Increment font size by 10% of base size per count
    const maxSize = dimensions.height / 2;
    return Math.min(calculatedSize, maxSize); // Ensure font size does not exceed half the container's height
};
/**/
const baseFontSize = dimensions.height * 0.2; // E.g., 3.5% of the container's height

const fontSizeSetter = (datum, index) => {
    // Define the scale factors for different buckets
    let scaleFactor;
    if (index <= 1) {
        scaleFactor = 1; // 100% of calculated size for indices 1-5
    } else if (index == 2) {
        scaleFactor = 0.9; // 100% of calculated size for indices 1-5
    } else if (index == 3) {
        scaleFactor = 0.8; // 100% of calculated size for indices 1-5
    } else if (index >=4 && index <= 8) {
        scaleFactor = 0.5; // 100% of calculated size for indices 1-5
    } else if (index >= 9 && index <= 15) {
        scaleFactor = 0.3; // 100% of calculated size for indices 1-5
    } else if (index >= 15 && index <= 25) {
        scaleFactor = 0.2; // 80% of calculated size for indices 6-15
    } else {
        scaleFactor = 0.1; // Smaller scale factor for indices beyond 50
    }

    const calculatedSize = baseFontSize * scaleFactor; 
    const maxSize = dimensions.height / 2;
    return Math.min(calculatedSize, maxSize); // Ensure font size does not exceed half the container's height
};


  const colorScale = scaleLinear({
    domain: [Math.min(...words.map(w => w.count)), Math.max(...words.map(w => w.count))],
    range: [0, 1],
    clamp: true
  });

  const colorInterpolator = interpolateRgb('#5555ff', '#ff0000'); // from blue to red

  const getColor = (count) => colorInterpolator(colorScale(count));

  return (
    <div ref={containerRef} className="wordcloud">
      <VisxWordcloud
        words={words}
        width={dimensions.width}
        height={dimensions.height}
        fontSize={fontSizeSetter}
        font={'Impact'}
        padding={2}
        spiral={spiralType}
        rotate={() => withRotation ? (Math.random() > 0.5 ? 60 : -60) : 0}
        random={Math.random}
      >
        {cloudWords =>
          cloudWords.map((w, i) => (
            <Text
              key={w.word}
              fill={getColor(w.count)}
              textAnchor={'middle'}
              transform={`translate(${w.x}, ${w.y}) rotate(${w.rotate})`}
              fontSize={w.size}
              fontFamily={w.font}
            >
              {w.word}
            </Text>
          ))
        }
      </VisxWordcloud>
    </div>
  );
};

export default WordCloud;
