import React, { useEffect, useState } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import Badge from 'react-bootstrap/Badge';

const TwitchChatSorter = ({ messages, keyword, maxSize }) => {
    const [commonMessages, setCommonMessages] = useState([]);

    // Function to count messages by removing spaces
    const countMessages = (messages) => {
        const messageCounts = {};
        messages.forEach(message => {
            const normalizedMessage = message.content.replace(/\s+/g, '').toLowerCase();
            messageCounts[normalizedMessage] = (messageCounts[normalizedMessage] || 0) + 1;
        });
        return messageCounts;
    };

    // Function to retrieve the top most common messages with more than one count
    const getTopMessages = (messageCounts) => {
        return Object.entries(messageCounts)
            .filter(([_, count]) => count > 1) // Filter out messages with only one occurrence
            .sort((a, b) => b[1] - a[1])
            .slice(0, 10)  // maxSize can be adjusted or default to 10
            .map(entry => ({ message: entry[0], count: entry[1] }));
    };

    useEffect(() => {
        // Filter messages based on the keyword, ignoring spaces
        const filteredMessages = keyword ? messages.filter(msg => 
            msg.content.replace(/\s+/g, '').toLowerCase().includes(keyword.replace(/\s+/g, '').toLowerCase())) 
            : messages;
        const messageCounts = countMessages(filteredMessages);
        setCommonMessages(getTopMessages(messageCounts));
    }, [messages, keyword]);

    return (
        <div>
            <h5 className="mt-2">
              Mash Chat {keyword ? ` containing "${keyword}"` : ""}
            </h5>
            <ListGroup variant="flush" as="ol" numbered>
                {commonMessages.map((msg, index) => (
                    <ListGroup.Item key={index} className="text-break" style={{ background:'#1a1d20', color:'#dee2e6' }}>
                      {msg.message} 
                      <Badge bg="primary" pill className="float-end">
                      {msg.count}
                      </Badge>
                    </ListGroup.Item>
                ))}
            </ListGroup>
        </div>
    );
};

export default TwitchChatSorter;