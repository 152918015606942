import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './components/Home';
import Poll from './components/Poll';
import { AuthContext } from './components/AuthContext';
import TwitchAuthApi from './components/TwitchAuthApi';

const App = () => {
    const [redirectPath, setRedirectPath] = useState(null);
    const { setToken, setUser } = useContext(AuthContext);

    useEffect(() => {
        const hash = window.location.hash;
        if (hash) {
            const params = new URLSearchParams(hash.substring(1));
            const token = params.get('access_token');

            if (token) {
                localStorage.setItem('twitch_access_token', token);
                setToken(token);

                TwitchAuthApi.getUserInfo(token).then(({ data, status }) => {
                    if (status === 200) {
                        setUser(data);
                        setRedirectPath('/poll');
                    } else {
                        setRedirectPath('/');
                    }
                }).catch(error => {
                    console.error('Failed to fetch user info:', error);
                    setRedirectPath('/');
                });

                window.location.hash = '';  // Clear the hash to clean up URL
            }
        }
    }, [setToken, setUser]);

    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/poll" element={<Poll />} />
                {redirectPath && <Route path="*" element={<Navigate to={redirectPath} replace />} />}
            </Routes>
        </Router>
    );
};

export default App;
