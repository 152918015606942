import React, { useEffect, useState } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';


const TwitchChatSorter = ({ 
  messages, 
  keyword, 
  maxSize = 200, 
  handleWordsUpdate,
  allowSpam,
  newChatMessages,
  allowBlacklist,
  blacklist
  }) => {
    const [commonWords, setCommonWords] = useState([]);
    const [allowEmotes, setAllowEmotes] = useState(true);
    const [runningMessageList, setRunningMessageList] = useState([]);
    const [runningMessageCount, setRunningMessageCount] = useState([]);
    
  useEffect(() => {
    if(messages.length === 0){
      setRunningMessageList([]);
      setRunningMessageCount([]);
      setCommonWords([]);
    }
  }, [messages]);

    
  useEffect(() => {
    ingestNewMessages(newChatMessages);
  }, [newChatMessages]);

const ingestNewMessages = (newMessages) => {
  let updatedList = [...runningMessageList, ...newMessages];
  let updatedCounts = {...runningMessageCount};

  // Process new messages
  newMessages.forEach(message => {
    //console.log("ingest message: "+ message.content);

    updatedCounts = processWords(message, updatedCounts, true);
  });

  // Expel old messages if necessary
  if (updatedList.length > maxSize) {
    const numberToExpel = updatedList.length - maxSize;
    updatedCounts = expellOldMessages(numberToExpel, updatedCounts);
    updatedList = updatedList.slice(-maxSize);
  }

  setRunningMessageList(updatedList);
  setRunningMessageCount(updatedCounts);
};

const processWords = (message, counts, isAdding) => {
  const contentLower = message.content.toLowerCase();
  if (!keyword || contentLower.includes(keyword.toLowerCase())) {
    // Split on spaces to initially separate terms
    const words = contentLower.split(/\s+/);

    // Apply blacklist conditionally based on allowBlacklist flag
    const filteredWords = words.filter(word =>
      word.length > 0 && !/^\d+$/.test(word) && (allowBlacklist || !blacklist.includes(word))
    );

    // Depending on allowSpam, either use all occurrences of words or unique ones.
    const wordsToProcess = allowSpam ? filteredWords : [...new Set(filteredWords)];

    wordsToProcess.forEach(word => {
      if (!counts[word]) {
        counts[word] = { count: 0 };  // Initialize if not already present
      }

      if (isAdding) {
        counts[word].count++;  // Increment for adding words
      } else {
        counts[word].count--;  // Decrement for removing words
        if (counts[word].count <= 0) {
          delete counts[word];  // Remove word from count if zero
        }
      }
    });
  }
  return counts;
};

const expellOldMessages = (numberToExpel, counts) => {
  const messagesToRemove = runningMessageList.slice(0, numberToExpel);

  messagesToRemove.forEach(message => {
    //console.log("removing message: "+ message.content);
    counts = processWords(message, counts, false);
  });

  return counts;
};
  
const updateCommonWords = (wordsCount) => {
  let filteredWords = Object.entries(wordsCount)
    .sort((a, b) => b[1].count - a[1].count)
    .map(([word, data]) => ({
      word,
      count: data.count
    }));

  setCommonWords(filteredWords.slice(0, 100));
};

  useEffect(() => {
    updateCommonWords(runningMessageCount);
    //console.log(runningMessageCount);
  }, [runningMessageCount]);
  
const recalcWords = (messages) => {
    const wordCounts = {};
    const emoteTexts = new Set();  // Collects all emote texts for filtering if allowEmotes is false

    // Collect all emote texts first
    messages.forEach(({ emoteData }) => {
        emoteData.forEach(({ emoteText }) => {
            emoteTexts.add(emoteText.toLowerCase());  // Normalize the text for consistent comparison
        });
    });

    // Now count the words, considering the allowSpam, allowEmotes, and keyword settings
    messages.forEach(({ content }) => {
        // Only process messages that contain the keyword if one is specified
        if (!keyword || content.toLowerCase().includes(keyword.toLowerCase())) {
            // Split content into words by spaces, apply blacklist and emote filters
            let words = content.toLowerCase().split(/\s+/).filter(word => 
                (allowBlacklist || !blacklist.includes(word)) && (allowEmotes || !emoteTexts.has(word))
            );

            if (!allowSpam) {
                // Use a Set to filter out duplicates if allowSpam is false
                words = [...new Set(words)];
            }

            // Count each word
            words.forEach(word => {
                if (!wordCounts[word]) {
                    wordCounts[word] = { count: 0 };
                }
                wordCounts[word].count++;
            });
        }
    });

    return wordCounts;
};
// Set up a timer to call recalcWords every 30 seconds
//this is incase a race condition causes expell/ingest to jump out of sync somehow
const intervalId = setInterval(() => {
    recalcWords(runningMessageList);
}, 30000); // 30000 milliseconds = 30 seconds

  useEffect(() => {
    const recalcCounts = recalcWords(runningMessageList);
    setRunningMessageCount(recalcCounts);
  }, [keyword, allowEmotes, allowSpam, allowBlacklist, blacklist]);


  useEffect(() => {
    handleWordsUpdate(commonWords);
  }, [commonWords]);


    return (
        <div>
            <h5 className="mt-2">
                Words
                <>
 
                  
                  <Form.Switch
                      type="switch"
                      className="float-end"
                      checked={allowEmotes}
                      onChange={() => setAllowEmotes(prevState => !prevState)}
                  />
                  <img 
                    className="float-end me-1"
                    
                    src={
                      allowEmotes ?
                      "https://cdn.frankerfacez.com/emote/483108/1"
                      : "https://cdn.betterttv.net/emote/5e486af4d736527d5cd2fed6/1x.webp" 
                    }
                    style={{ height: '28px' }}
                  />
                </>
                
            </h5>
            <ListGroup variant="flush" as="ol" numbered>
                {commonWords.slice(0, 15).map((item) => (
                    <ListGroup.Item
                      className="text-break"
                      key={item.word} 
                      style={{ background: '#1a1d20', color: '#dee2e6' }}
                    >
                      {item.url ? (
                        <>
                          <img
                            src={item.url}
                            style={{ marginRight: 5, height: '28px' }} 
                          />
                          {item.word}
                        </>
                      ) : item.word}
                      <Badge bg="primary" pill className="float-end">
                        {item.count}
                      </Badge>
                    </ListGroup.Item>
                ))}
            </ListGroup>
        </div>
    );
};

export default TwitchChatSorter;
