import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import WordCloud from './WordCloud'; // Import your custom WordCloud component

const WordCloudModal = ({ words }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <button 
              className={`btn float-end btn-primary mx-2 rounded-pill border-0`}
              onClick={handleShow}
            >
              Word Cloud
            </button>
            <Modal show={show} onHide={handleClose} size="xl">
                <Modal.Body>
                        {/* Ensure WordCloud is only rendered if there are words to display */}
                        <WordCloud words={words} />
                </Modal.Body>
                
            </Modal>
        </>
    );
};

export default WordCloudModal;
