// utils/fetchFFZEmotes.js

/**
 * Fetches 7TV emotes for a given Twitch user ID and a specific 7TV user ID.
 * @param {string} twitchId - The Twitch user ID.
 * @returns {Promise<Array>} An array of emote objects.
 */
const fetchFFZEmotes = async (channelId) => {
    const headers = { 'Content-Type': 'application/json' };

    const ffzUrl = `https://api.frankerfacez.com/v1/room/id/${channelId}`;
    
    try {
        const response = await fetch(ffzUrl, {headers});
        //console.log(response);
        const data = await response.json();
        if (data.error) {
            console.error('Failed to fetch FFZ emotes:', data.message);
            return [];
        }
        //console.log(data);
        // Assuming the structure of the data includes sets which contain emotes
        const emotes = [];
        Object.values(data.sets).forEach(set => {
            set.emoticons.forEach(emote => {
                emotes.push({
                    name: emote.name,
                    id: emote.id,
                    url: emote.urls['1'] // or '2' or '4' for different resolutions
                });
            });
        });
        return emotes;
    } catch (error) {
        console.error('Error fetching FFZ emotes:', error);
        return [];
    }
    
    return [];
};

export default fetchFFZEmotes;
