// utils/fetch7TVEmotes.js

/**
 * Fetches 7TV emotes for a given Twitch user ID and specific 7TV user IDs.
 * @param {string} twitchId - The Twitch user ID.
 * @returns {Promise<Array>} An array of emote objects.
 */
const fetch7TVEmotes = async (twitchId) => {
    const headers = { 'Content-Type': 'application/json' };
    const seventvUserId = '61d2f5b3825ae71d82bf37a8'; // Example user ID
    const chunkyUserId = '62fef7534d3c940504dd83a3'; // Another example user ID

    // Helper function to fetch emotes for a 7TV user ID
    const fetchEmotesForUser = async (userId) => {
        try {
            const userDetailsUrl = `https://7tv.io/v3/users/${userId}`;
            const userDetailsResponse = await fetch(userDetailsUrl, { headers });
            //console.log(userDetailsResponse);
            if (!userDetailsResponse.ok) {
                throw new Error(`Failed to fetch user details: ${userDetailsResponse.status}`);
            }
            const userDetails = await userDetailsResponse.json();

            const emoteSets = userDetails.connections.map(connection => connection.emote_set.id);
            const emoteSetPromises = emoteSets.map(async (emoteSetId) => {
                const emotesUrl = `https://7tv.io/v3/emote-sets/${emoteSetId}`;
                const emotesResponse = await fetch(emotesUrl, { headers });
                if (!emotesResponse.ok) {
                    throw new Error(`Failed to fetch emotes for emote set ${emoteSetId}: ${emotesResponse.statusText}`);
                }
                const emoteData = await emotesResponse.json();
                return emoteData.emotes.map(emote => ({
                    name: emote.name,
                    id: emote.id,
                    url: emote.data.host.url + "/1x.webp"
                }));
            });

            const emotes = await Promise.all(emoteSetPromises);
            return emotes.flat();
        } catch (error) {
            console.error(`Error fetching emotes for user ${userId}:`, error);
            return [];
        }
    };
    
    const fetchEmotesBySet = async (emote_set) => {
      const emotesUrl = `https://7tv.io/v3/emote-sets/${emote_set}`;
      const emotesResponse = await fetch(emotesUrl, { headers });
      if (!emotesResponse.ok) {
          throw new Error(`Failed to fetch emotes for emote set ${emote_set}: ${emotesResponse.statusText}`);
      }
      const emoteData = await emotesResponse.json();
      return emoteData.emotes.map(emote => ({
          name: emote.name,
          id: emote.id,
          url: emote.data.host.url + "/1x.webp"
      }));
    }

    // Fetch personal emotes directly from the user details
    const fetchPersonalEmotes = async (userId) => {
        try {
            const userDetailsUrl = `https://7tv.io/v3/users/${userId}`;
            const userDetailsResponse = await fetch(userDetailsUrl, { headers });
            if (!userDetailsResponse.ok) {
                throw new Error(`Failed to fetch user details: ${userDetailsResponse.status}`);
            }
            const userDetails = await userDetailsResponse.json();
            //console.log("userDetails");
            //console.log(userDetails);
            if (Array.isArray(userDetails.emotes)) {
              return userDetails.emotes.map(emote => ({
                  name: emote.name,
                  id: emote.id,
                  url: emote.data.host.url + "/1x.webp"
              }));
            }
            else{
              return[];
            }
        } catch (error) {
            console.error(`Error fetching personal emotes for user ${userId}:`, error);
            return [];
        }
    };

    try {
        const twitchUserIdResponse = await fetch(`https://7tv.io/v3/users/twitch/${twitchId}`, { headers });
        if (!twitchUserIdResponse.ok) {
            throw new Error(`Failed to fetch 7TV user ID from Twitch ID ${twitchId}: ${twitchUserIdResponse.statusText}`);
        }
        const twitchUserData = await twitchUserIdResponse.json();
        const twitchUserEmotes = await fetchEmotesForUser(twitchUserData.user.id);
        const twitchPersonalEmotes = await fetchPersonalEmotes(twitchUserData.user.id);
        const seventvUserEmotes = await fetchEmotesBySet("61d2f5b3825ae71d82bf37a8");
        const chunkyUserEmotes = await fetchEmotesBySet("6622f62159a94de4ca07f00d");
        const globalEmotes = await fetchEmotesBySet('global'); // Assuming global emotes are available under a 'global' ID

        return [
            ...twitchUserEmotes,
            ...twitchPersonalEmotes,
            ...seventvUserEmotes,
            ...chunkyUserEmotes,
            ...globalEmotes
        ];
    } catch (error) {
        console.error("Error fetching 7TV emotes:", error);
        return [];
    }
};

export default fetch7TVEmotes;
